import React from "react"
import { Link } from "gatsby"

// shows the header for blog posts. Or just author header without a post
const AuthorHeader = ({author}) => {

  if (!author) return 'missing author'

  return (
    <div className="text-black flex bg-yellow-100 text-rose-500 p-4 rounded-2xl items-center">
      {author.frontmatter.authorImage ? (
        <img className="h-24 w-24 bg-yellow-200 rounded-full object-cover mr-6" src={author.frontmatter.authorImage} alt={author.frontmatter.author} />
      ) : ''}
      
      <div>
        <div className="bold text-xl">By <Link to={`/author/${author.frontmatter.slug}/`}>{author.frontmatter.name}</Link></div>
        <div className="mt-2 font-medium">{author.frontmatter.description}</div>
      </div>
    </div>
  );
}

export default AuthorHeader;

import React from "react"
import { Link } from "gatsby"

import Image from "../common/image"
import { capitalizeFirst } from "../../utils/text"

const BlogPostCard = ({node, mini}) => {

  const imagesPrefix = '../../images/';

  return (
    <div key={node.frontmatter.slug} className="flex group flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="flex-1 bg-white text-rose-500 p-6 flex flex-col justify-between">
        <div className="flex-1">
          {/*{!mini ? (
            <div className="text-sm font-medium text-yellow-500 opacity-50 mb-2">
              <div>
                Tags go here?
              </div>
            </div>
          ) : ''}*/}
          <Link to={`/blog/${node.frontmatter.slug}/`} className="block">
            <p className="text-xl font-semibold text-rose-500">{node.frontmatter.title}</p>
            <p className="mt-3 text-base text-gray-500">{node.frontmatter.description}</p>
          </Link>
        </div>

        <div className="mt-4 flex items-center">
          {node.frontmatter.authorImage ? (
            <img className="h-10 w-10 bg-yellow-200 rounded-full object-cover mr-2" src={node.frontmatter.authorImage} alt={node.frontmatter.author} />
          ) : ''}
          <div className="">
              <div className="text-sm font-medium text-rose-500">
                <div className="">
                  {capitalizeFirst(node.frontmatter.author)}
                </div>
              </div>
              <div className="flex space-x-1 text-sm text-gray-500">
                {/*<time dateTime={node.frontmatter.published}>{node.frontmatter.published}</time>
                <span className="px-1 opacity-75" aria-hidden="true">&middot;</span>*/}
                <span className="opacity-50">{node.timeToRead} min read</span>
              </div>
            </div>
        </div>

      </div>
    </div>
  );
}

export default BlogPostCard;
